<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Vendors/List</h4>
          <div>
            <router-link :to="can('vendor-create') ? {name:'appVendorCreate'} : '#'">
              <span :title="can('vendor-create') ? 'Create' : 'Create Forbidden'"
                    class="glow d-flex align-items-center">
                <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create</span>
            </router-link>
          </div>
        </div>

        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center ">
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Show Per Page</label>
                <VueMultiselect :allowEmpty="false" v-model="selectedPagination" class="" :options="pagination"
                                :close-on-select="true"
                                label="name" track-by="value" :show-labels="false"/>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Role</label>
                <VueMultiselect :allowEmpty="false" v-model="selectedRole" class="" :options="roleList"
                                :close-on-select="true" label="name"
                                track-by="value" :show-labels="false"/>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>State</label>
                <VueMultiselect :allowEmpty="false" v-model="selectedState" class="" :options="stateList"
                                :close-on-select="true" label="name"
                                track-by="value" :show-labels="false"/>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Status</label>
                <VueMultiselect :allowEmpty="false" v-model="selectedStatus" class="" :options="statusList"
                                :close-on-select="true"
                                placeholder="Select status" label="name" track-by="value" :show-labels="false"/>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-12 col-sm-12 col-md-9  col-lg-9">
                <input v-model="getVendorsParams.where_has_user_query" class="form-control search-product-input-element"
                       type="text" placeholder="Search by name or email or phone">
              </div>
              <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                        @click="applyVendorFilter(null)">Filter
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{width:'20%'}">NAME</th>
                    <th class="position-relative" :style="{width:'18%'}">ADDRESS</th>
                    <th class="position-relative" :style="{width:'6%'}">Role</th>
                    <th class="position-relative" :style="{width:'6%'}">Code</th>
                    <th class="position-relative" :style="{width:'20%'}">EMAIL</th>
                    <th class="position-relative" :style="{width:'15%'}">PHONE</th>
                    <th class="position-relative" :style="{width:'10%'}">STATUS</th>
                    <th class="position-relative" :style="{width:'5%'}">ACTIONS</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(vendor) in vendors" :key="vendor.id">
                    <td>
                      <span v-if="vendor.user" class="text-capitalize">{{
                          `${vendor.user.first_name} ${vendor.user.last_name}`
                        }}</span>
                    </td>
                    <td>
                      <span v-if="vendor.address">{{
                          vendor.address.street
                        }}, {{ vendor.address.suburb }} {{
                          vendor.address.state
                        }} {{ vendor.address.post_code }}, {{ vendor.address.country }}</span>
                    </td>
                    <td>
                      <span v-if="vendor.user.role">{{ vendor.user.role.name }}</span>
                    </td>
                    <td>
                      <span v-if="vendor.code">{{ vendor.code }}</span>
                    </td>

                    <td>
                      <span v-if="vendor.user">{{ vendor.user.email }}</span>
                    </td>
                    <td>
                      <span v-if="vendor.user">{{ vendor.user.phone_number }}</span>
                    </td>
                    <td>
                      <span v-if="vendor" class="badge badge-light-success">{{ vendor.status }}</span>
                    </td>

                    <td>
                      <div class="d-flex align-items-top justify-content-between">
                        <router-link class="text-decoration-none view-edit-delte-icon"
                                     style="height: 18px"
                                     :to="can('vendor-view') ? {name:'appVendorView',params:{id:vendor.id}} : '#'"
                                     :title="can('vendor-view') ? 'View' : 'View Forbidden'">
                          <i class='bx bx-show'></i>
                        </router-link>
                        <div class="dropdown py-0 px-0 view-edit-delete-icon" :style="{height:'18px'}">
                          <span type="button" class="px-0 py-0" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                            <i class='bx bx-dots-vertical-rounded h-100'></i>
                          </span>
                          <div class="dropdown-menu">
                            <button class="dropdown-item"
                                    :disabled="!can('vendor-delete')"
                                    data-toggle="modal" data-target="#vendorDeleteAlertModal"
                                    @click="this.modelIdForDelete = vendor.id">
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <ListPagination position="right" :pagination-data="paginateLinks" @getClickedPage="applyVendorFilter"/>
              </div>
            </div>
          </div>
        </div>
        <!-- vendor delete modal -->
        <VendorDeleteAlertModal modal-name="vendorDeleteAlertModal" :model-id="modelIdForDelete" model-name="vendor"
                                @confirmModelDeletion="onConfirmModelDeletion"/>
      </section>
    </template>
  </AppLayout>
</template>

<script>
//components
import AppLayout from '@/layouts/backEnd/AppLayout'
import VendorDeleteAlertModal from '@/components/backEnd/modal/DeleteAlertModal';
import ListPagination from '@/components/backEnd/pagination/ListPagination';
import Authorization from "@/components/backEnd/mixins/Authorization";
// mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
// core packages
import {mapActions, mapGetters} from "vuex";

export default {
  name: "VendorList",
  mixins: [ShowToastMessage, Loader, Authorization],
  components: {
    AppLayout,
    VendorDeleteAlertModal,
    ListPagination,
  },
  data() {
    return {
      modelIdForDelete: '',
      selectedPagination: {
        value: '',
        name: 'Default'
      },
      selectedRole: {
        value: '',
        name: 'Any'
      },
      selectedState: {
        value: '',
        name: 'Any'
      },
      selectedStatus: {
        value: '',
        name: 'Any'
      },
      getVendorsParams: {
        where_has_user_query: '',
        where_has_address_state: '',
        where_has_user_role_id: '',
        status: '',
        with_relation: ['user', 'user.role', 'address'],
        order_by_id: 'DESC',
        paginate: 1,
        pagination: '',
        page: ''
      },
      getSettingsParams: {
        type: ['default'],
        key: ['default_pagination', 'default_state'],
      },
      getRolesParams: {
        type: 6 // vendor
      },
    }

  },
  watch: {
    selectedPagination(selectedPagination) {
      this.getVendorsParams.pagination = selectedPagination.value;
    },
    selectedState(selectedState) {
      this.getVendorsParams.where_has_address_state = selectedState.value;
    },
    selectedRole(selectedRole) {
      this.getVendorsParams.where_has_user_role_id = selectedRole.value;
    },
    selectedStatus(selectedStatus) {
      this.getVendorsParams.status = selectedStatus.value;
    },
  },
  computed: {
    ...mapGetters({
      vendors: 'appVendors/vendors',
      paginateLinks: 'appVendors/paginateLinks',
    }),
    statusList() {
      return [
        {value: '', name: 'Any'},
        {value: 0, name: 'Inactive'},
        {value: 1, name: 'Active'},
      ];
    },
    pagination() {
      return [{value: this.$store.getters['appSettings/settingDefaultPagination'].value, name: 'Default'}, {
        value: 25,
        name: '25 Entries'
      }, {value: 50, name: '50 Entries'}, {value: 100, name: '100 Entries'}];
    },
    stateList() {
      return [{value: '', name: 'Any'}, ...this.$store.getters['appSettings/settingDefaultState'].value];
    },
    roleList() {
      let activeRoles = this.$store.getters['appRoles/roles'].map((roleObj) => {
        let roleId = roleObj.id;
        let roleName = roleObj.name;

        return {value: roleId, name: roleName};
      });

      return [{value: '', name: 'Any'}, ...activeRoles];
    },
  },
  methods: {
    ...mapActions({
      resetRoles: 'appRoles/resetRoles',
      resetVendors: 'appVendors/resetVendors',

      getSettings: 'appSettings/getSettings',
      getRoles: 'appRoles/getRoles',
      getVendors: 'appVendors/getVendors',
      deleteVendorOnList: 'appVendors/deleteVendorOnList',
    }),
    async deleteSingleVendorOnLIst(id) {
      this.deleteVendorOnList(id).then(async (response) => {
        if (response.status === 200 || response.status === 204) {
          const toastObj = {
            message: "Vendor deleted successfully",
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      });
    },

    async onConfirmModelDeletion(confirmModelDeletion) {
      await this.deleteSingleVendorOnLIst(confirmModelDeletion.modelId);
    },

    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

    async getRoleList() {
      await this.getRoles(this.getRolesParams);
    },

    async getVendorList() {
      await this.getVendors(this.getVendorsParams).then(async (response) => {
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },

    async applyVendorFilter(pageNumber) {
      this.getVendorsParams.page = pageNumber;
      await this.loader(true);
      await this.getVendorList();
      await this.loader(false);
    },
  },
  async mounted() {
    this.loader(true);
    await this.getSettingList();
    await this.getRoleList();
    await this.getVendorList();
    this.loader(false);
  },
  async beforeUnmount() {
    await this.resetRoles();
    await this.resetVendors();
  },

}
</script>

<style scoped>
.view-edit-delete-icon {
  color: #b3c0ce;
  transition: all 0.2s;
}

.view-edit-delete-icon:hover {
  color: #5a8dee;
}

.badge {
  display: inline-block;
  padding: 4px 8px !important;
  font-size: 10px !important;
  font-weight: 400;
  line-height: 1 !important;
  text-align: center !important;
  white-space: nowrap !important;
  vertical-align: baseline !important;
  border-radius: 0.375rem !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>